<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
@font-face {
  font-family: Vazir;
  src: url("./assets/Fonts/vazir/Vazir.eot");
  src: url("./assets/Fonts/vazir/Vazir.eot?#iefix") format("embedded-opentype"),
    url("./assets/Fonts/vazir/Vazir.woff2") format("woff2"),
    url("./assets/Fonts/vazir/Vazir.woff") format("woff"),
    url("./assets/Fonts/vazir/Vazir.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
*{font-family:Vazir ;
letter-spacing:0}
</style>
